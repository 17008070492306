import { useSwiper } from 'swiper/react';
import { Button } from '@marriott/mi-ui-library';

import { PostcardContainerProps } from './PostcardContainer.types';
import { getClickTrackValue } from '../../utils/getClickTrackValue';
import { POSTCARD_CONSTANTS } from '../../utils';

export const PostcardContainerButton = (props: PostcardContainerProps) => {
  const { trackingProperties } = props;
  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };
  const swiper = useSwiper();
  return (
    <div className="d-flex flex-row carousel-arrow-container">
      <Button
        data-testid={props.direction === 'left' ? 'carousel-left-arrow' : 'carousel-right-arrow'}
        trackingProperties={trackingProperties}
        className={props.direction === 'left' ? 'carousel-left-arrow' : 'carousel-right-arrow'}
        aria-label={props.direction === 'left' ? 'Prev' : 'Next'}
        callback={() => {
          props.handleMobileClick();
          if (props.direction === 'left') {
            return swiper.slidePrev();
          } else return swiper.slideNext();
        }}
        custom_click_track_value={getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks: {
            position: POSTCARD_CONSTANTS.POSITION,
            description:
              props.direction === 'left' ? POSTCARD_CONSTANTS.LEFT_ARROW_BELOW : POSTCARD_CONSTANTS.RIGHT_ARROW_BELOW,
          },
        })}
      >
        <span className={props.direction === 'left' ? 'icon-arrow-left' : 'icon-arrow-right'}></span>
      </Button>
    </div>
  );
};
