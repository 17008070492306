/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { useEffect, useRef, useState } from 'react';
import { A11y, Keyboard, Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { respGridUtil } from '@marriott/mi-ui-library';
import { getClickTrackValue } from '../../utils/getClickTrackValue';

import { Postcard } from '../../molecules/Postcard';
import { PostcardProps } from '../../molecules/Postcard/Postcard.types';
import { StyledSwiper, StyledSwiperSlide, StyledPlayPauseButton } from './PostcardContainer.styles';
import { PostcardContainerProps } from './PostcardContainer.types';
import { PostcardContainerButton } from './PostcardContainerButton';

import { getWCMModeFlagValue } from '@marriott/shared/mi-helper-utils';
import { POSTCARD_CONSTANTS } from '../../utils';

const wcmModeDisabled = getWCMModeFlagValue();

const PostcardContainerComp = (props: PostcardContainerProps) => {
  const cqModel = respGridUtil(props.model);
  const cqPostcardsObject = respGridUtil(cqModel.cqItems[cqModel.cqItemsOrder[0]]);
  const postcardArray: PostcardProps[] =
    cqPostcardsObject.cqItemsOrder?.map((id: string) => cqPostcardsObject.cqItems[id]) || [];

  const swiperRef = useRef<any>();

  const { trackingProperties } = props;
  const trackingPropertiesObject = { ...trackingProperties, trackingDescription: '' };
  const [loop, setLoop] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [swiperTouched, setSwiperTouched] = useState(false);

  const handlePause = () => {
    swiperRef.current.autoplay.pause();
    setIsPaused(true);
  };

  // This is to handle an edge case on the navigation buttons where they don't pause
  const handleStop = () => {
    swiperRef.current.autoplay.stop();
    setIsPaused(true);
  };

  const handleResume = () => {
    // Related to edge case above
    if (!swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.start();
    }
    swiperRef.current.autoplay.resume();
    setIsPaused(false);
  };

  const [activeSlideWidth, setActiveSlideWidth] = useState(0);
  const [activeImageWidth, setActiveImageWidth] = useState(0);

  const swiperContainerRef = useRef(null);

  const togglePlayPause = () => {
    if (swiperRef.current.autoplay.paused || !swiperRef.current.autoplay.running) {
      handleResume();
      setSwiperTouched(false);
    } else {
      handlePause();
    }
  };
  const handleEventListener = (event: any) => {
    if (['ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      setLoop(false);
      handlePause();
    } else setLoop(true);
  };

  useEffect(() => {
    // because we don't initialize swiper in author mode, none of these elements are present, so just return
    if (props.isAuthorMode && !wcmModeDisabled) {
      return;
    }

    // Converting to never because of "never" error
    const activeSlide = (swiperContainerRef?.current as unknown as HTMLElement)?.querySelector(
      '.swiper-slide-active'
    ) as HTMLElement;
    const activeSlideImage = (swiperContainerRef?.current as unknown as HTMLElement)?.querySelector(
      '.swiper-slide-active > div'
    ) as HTMLElement;
    const nextButton = (swiperContainerRef?.current as unknown as HTMLElement)?.querySelector(
      '.swiper-button-next'
    ) as HTMLElement;
    const prevButton = (swiperContainerRef?.current as unknown as HTMLElement)?.querySelector(
      '.swiper-button-prev'
    ) as HTMLElement;

    const slideResizeObserver = new ResizeObserver(slides => {
      for (const slide of slides) {
        const width = slide.contentRect.width;
        setActiveSlideWidth(width);
      }
    });

    const imageResizeObserver = new ResizeObserver(images => {
      for (const image of images) {
        const width = image.contentRect.width;
        setActiveImageWidth(width);
      }
    });

    slideResizeObserver.observe(activeSlide);
    imageResizeObserver.observe(activeSlideImage);

    const updateArrowPosition = () => {
      if (nextButton && prevButton) {
        // to accommodate for the visible slides
        const visibleSlideWidth = activeSlideWidth * 1.2;
        const arrowOffset = (visibleSlideWidth - activeImageWidth) / 2;
        nextButton.style.right = `${arrowOffset - 20}px`;
        prevButton.style.left = `${arrowOffset - 20}px`;
      }
    };
    updateArrowPosition();

    return () => {
      slideResizeObserver.disconnect();
      imageResizeObserver.disconnect();
    };
  }, [activeSlideWidth, activeImageWidth, props.isAuthorMode]);

  // custom listener for tab and arrow events to set loop to true/false
  // a11y breaks the focus, so created custom event so that keyboard users can get out of the swiper carousel
  // if user clicks on arrows (or anything) the carousel can loop
  // if users tabs or uses right or left arrows, they can't loop
  useEffect(() => {
    document.addEventListener('keydown', handleEventListener);
    document.addEventListener('click', handleEventListener);

    return () => {
      document.removeEventListener('keydown', handleEventListener);
      document.removeEventListener('click', handleEventListener);
    };
  }, []);

  return props.isAuthorMode && !wcmModeDisabled ? (
    <ResponsiveGrid
      gridClassNames={''}
      columnClassNames={{
        responsivegrid: '',
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pagePath={props?.pagePath}
      itemPath={`${props?.itemPath}/container-0`}
      config={{
        isEmpty: () => true,
        resourceType: 'mi-aem-common-spa/components/container',
      }}
    />
  ) : (
    <StyledSwiper
      ref={swiperContainerRef}
      onTouchEnd={() => {
        setSwiperTouched(true);
      }}
      onTransitionEnd={() => {
        if (swiperTouched) {
          handlePause();
        }
      }}
      onSwiper={(swiper: Swiper) => {
        swiperRef.current = swiper;

        swiper.navigation.prevEl.dataset['custom_click_track_value'] = getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks: { position: POSTCARD_CONSTANTS.POSITION, description: POSTCARD_CONSTANTS.LEFT_ARROW },
        });
        swiper.navigation.prevEl.dataset['isClickTrack'] = 'true';
        swiper.navigation.prevEl.classList.add('custom_click_track', 'inverse');
        swiper.navigation.prevEl.setAttribute('tabIndex', '-1');
        swiper.navigation.prevEl.addEventListener('click', handleStop);
        swiper.navigation.prevEl.setAttribute('data-testid', 'swiper-button-prev');

        swiper.navigation.nextEl.dataset['custom_click_track_value'] = getClickTrackValue({
          trackingProperties: trackingPropertiesObject,
          fallbacks: { position: POSTCARD_CONSTANTS.POSITION, description: POSTCARD_CONSTANTS.RIGHT_ARROW },
        });
        swiper.navigation.nextEl.dataset['isClickTrack'] = 'true';
        swiper.navigation.nextEl.classList.add('custom_click_track', 'inverse');
        swiper.navigation.nextEl.setAttribute('tabIndex', '-1');
        swiper.navigation.nextEl.addEventListener('click', handleStop);
        swiper.navigation.nextEl.setAttribute('data-testid', 'swiper-button-next');
      }}
      spaceBetween={0}
      slidesPerView={1.2}
      centeredSlides={true}
      pagination={{
        el: '.swiper-pagination', // Use a valid DOM element here
        type: 'bullets',
        enabled: true,
        clickable: false,
      }}
      navigation={true}
      modules={[Navigation, Pagination, Keyboard, A11y, Autoplay]}
      keyboard={{ enabled: true }}
      loop={loop}
      autoplay={{
        delay: 5000,
        // Should be default, but isn't
        disableOnInteraction: false,
      }}
    >
      {postcardArray.map((postcard, index) => (
        <StyledSwiperSlide key={index} tabIndex={0} onClick={handlePause}>
          <Postcard {...postcard} index={index} />
        </StyledSwiperSlide>
      ))}
      <div className="d-flex flex-row align-self-center mt-2 w-auto">
        <StyledPlayPauseButton callback={togglePlayPause}>
          <div data-testid="play-pause-button">
            <span className={isPaused ? 'icon-play icon-m' : 'icon-pause icon-m'}></span>
          </div>
        </StyledPlayPauseButton>
        <PostcardContainerButton direction="left" {...props} handleMobileClick={handleStop} />
        <div className="swiper-pagination"></div>
        <PostcardContainerButton direction="right" {...props} handleMobileClick={handleStop} />
      </div>
    </StyledSwiper>
  );
};

export const PostcardContainer = (props: PostcardContainerProps) => {
  return props.isAuthorMode ? (
    <EditableComponent
      config={{
        emptyLabel: 'postcardcontainer',
        isEmpty: () => false,
        resourceType: 'mi-aem-homepage-spa/components/content/postcardcontainer',
      }}
      {...props}
    >
      <PostcardContainerComp {...props} />
    </EditableComponent>
  ) : (
    <PostcardContainerComp {...props} />
  );
};
