/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ASPECT_RATIO, IMG_TYPE_JPG } from '../constants/OffersHeroConstants';
import {
  ASPECT_RATIO_UXL_FIELD_MAPPING,
  constants,
  IMAGE_CACHE_DOMAIN,
  PRIMARY_MEDIA,
  SECONDARY_MEDIA,
  ALLOWED_TAGS_IN_DESCRIPTION,
  WIDE_HOR_IMG,
} from '../constants/CommonConstants';
import { generateI18Title, generateTitle, generateOfferUrl, getCookieValue, extractValue } from './CommonUtils';
import { useTranslation } from 'react-i18next';
import {
  MARRIOTT_DOMAIN_CN,
  MBOX_COOKIE,
  ASPECT_RATIO_SQUARE,
  ASPECT_RATIO_WIDE,
  CARD_LAYERED_VARIATION,
  CARD_LAYERED_WIDE,
  CARDSTYPE_VERTICAL,
  DESTINATION_OFFERS_RECIPE,
  IMAGE_ALT_TEXT,
  IMPRESSION_TRACK,
  LAYERED_WIDE,
  MERCHANDISING_CATEGORY,
  MEMBER_EXCLUSIVE,
  MERCHANDISING_OFFER,
} from '../constants/OfferCardConstants';
import DOMPurify from 'isomorphic-dompurify';
import { TimeLeft } from '../organisms/OffersMBEHero/OffersMBEHero.types';
import config from '../organisms/OfferCardCarousel/config';
import { CardVerticalVariations } from '@marriott/mi-ui-library';
import { OffersSearchInputVar, ResortRequestVariables } from '../organisms/OfferCardCarousel/OfferCardCarousel.types';

//SEO dynamic expression pattern for AEM model values
const { MARRIOTT_DEALS, REQUIRED_ASPECT_RATIO, OFFER_IMAGE_CACHE_DOMAIN, NO_INDEX_NO_FOLLOW, NO_INDEX_FOLLOW } =
  constants;

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const validateDetailsField = (value?: string): boolean => {
  if (value === null || value === '' || value === 'No Message Found') {
    return false;
  } else {
    return true;
  }
};

export const getFallbackImg = (url: string, imgAspectRatio: string): string => {
  const aspectRatio = imgAspectRatio.toUpperCase();
  switch (aspectRatio) {
    case 'WIDE':
      return `${url}-${ASPECT_RATIO['Wide']}${IMG_TYPE_JPG}`;

    case 'FEATURE':
      return `${url}-${ASPECT_RATIO['Feature']}${IMG_TYPE_JPG}`;

    case 'CLASSIC':
      return `${url}-${ASPECT_RATIO['Classic']}${IMG_TYPE_JPG}`;

    case 'PANO':
      return `${url}-${ASPECT_RATIO['Pano']}${IMG_TYPE_JPG}`;

    default:
      return `${url}-${ASPECT_RATIO['Square']}${IMG_TYPE_JPG}`;
  }
};

export const decodeHtml = (text: string): string => {
  if (typeof document !== 'undefined') {
    const fragment = DOMPurify.sanitize(text, {
      ALLOWED_TAGS: [
        'b',
        'strong',
        'i',
        'em',
        'u',
        's',
        'del',
        'sub',
        'sup',
        'mark',
        'small',
        'big',
        'ul',
        'ol',
        'li',
        'dl',
        'dt',
        'dd',
        'table',
        'tr',
        'td',
        'th',
        'thead',
        'tbody',
        'tfoot',
        'colgroup',
        'col',
        'caption',
        'p',
        'br',
        'hr',
        'blockquote',
        'pre',
        'a',
        'code',
        'span',
        'div',
        'img',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
      ],
      RETURN_DOM: true,
    });
    // Convert the DOM Fragment to string
    let sanitizedHtml = fragment.innerHTML ?? '';
    // Replace sequences of multiple spaces with corresponding number of non-breaking spaces
    sanitizedHtml = sanitizedHtml.replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length));
    return sanitizedHtml;
  }
  return text;
};

export const returnDecodedString = (input: string): string => {
  return input.replaceAll('&amp;', ' &');
};

export const getLocalizedDate = (date: string, lang: string | undefined, yearText: string, OfText: string): string => {
  let day = moment(date).format('DD').trim();
  const year = moment(date).format('YYYY').trim();
  let month;
  let updatedDate = '';

  if (
    lang === 'en-US' ||
    lang === 'it-IT' ||
    lang === 'fr-FR' ||
    lang === 'de-DE' ||
    lang === 'ru-RU' ||
    lang === 'en-GB'
  ) {
    month = moment(date).format('MMMM').trim();
    if (lang === 'en-US') {
      updatedDate = `${month} ${day}, ${year}`;
    } else if (lang === 'de-DE') {
      updatedDate = `${day}. ${month} ${year}`;
    } else if (lang === 'ru-RU') {
      updatedDate = `${day} ${month} ${year} r.`;
    } else if (lang === 'it-IT' || lang === 'fr-FR' || lang === 'en-GB') {
      updatedDate = `${day} ${month} ${year}`;
    }
  } else if (lang === 'zh-CN' || lang === 'ja-JP') {
    month = moment(date).format('MMM').trim();
    updatedDate = `${year}${yearText}${month}${day}日`;
  } else if (lang === 'ko-KR') {
    day = moment(date).format('D').trim();
    month = moment(date).format('MMM').trim();
    updatedDate = `${year}${yearText} ${month} ${day}일`;
  } else if (lang === 'es-ES' || lang === 'pt-BR') {
    month = moment(date).format('MMMM').trim();
    updatedDate = `${day} ${OfText} ${month} ${OfText} ${year}`;
  }
  return updatedDate;
};

export const processDynamicProps = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const marriottDealsLocalisedValues = {
    marriottDeals: t(MARRIOTT_DEALS),
    inText: t('in'),
  };
  const seoData = props?.productSpecificResponse;
  const apiData = props.productSpecificResponse?.responseObject?.edges[0]?.node;
  const facetData = props.productSpecificResponse?.responseObject?.facets;
  const languageCodes = props?.isPreview
    ? []
    : facetData?.filter((obj: any) => obj?.type?.description === 'locale')[0]?.buckets?.map((obj: any) => obj.code);
  const titleFormat = props.model?.metaProperties?.filter(
    (obj: any) => obj.name === 'og:title' && obj?.value?.trim() !== ''
  );
  const title = titleFormat?.length
    ? generateI18Title(apiData, titleFormat[0])
    : generateTitle(apiData, marriottDealsLocalisedValues);
  const imageArr = apiData?.photos?.images ?? [];
  const image = imageArr.find((img: any) => img.aspectRatio.toUpperCase() === REQUIRED_ASPECT_RATIO);

  if (seoData?.marsha) {
    if (props?.sessionData?.cacheData?.data) {
      const sessionDataObj = props.sessionData.cacheData;
      const propHeaderObj = sessionDataObj.data;
      const ariesResObj = {
        propertyId: seoData.marsha,
        marshaRate: false,
        searchCriteriaChanged: false,
        numberOfRooms: 1,
        lengthOfStay: '1',
        rateListMenuViewType: 'rooms',
        singleDateLimit: 1728259199999,
        groupDateLimit: 1728259199999,
        availabilitySearchSourceHWS: false,
      };
      props['sessionData']['locale'] = props?.headersData[`accept-language`]
        ? props.headersData[`accept-language`].split(',')?.[0]
        : null;
      props['sessionData']['brandCode'] = props?.productSpecificResponse?.brand ?? '';
      propHeaderObj['AriesReservation'] = ariesResObj;
      sessionDataObj['data'] = propHeaderObj;
      props['sessionData']['cacheData'] = sessionDataObj;
    }
  }
  const updatedUrl: {
    url: string;
    hreflang: string;
  }[] = generateOfferUrl(
    apiData.urlTitle ?? '',
    apiData.id ?? '',
    apiData.seoNickname ?? '',
    apiData.numProperties ?? '',
    seoData?.marsha ?? '',
    [],
    seoData?.locale,
    seoData?.isPreview === 'true'
  );

  const hreflangList: {
    url: string;
    hreflang: string;
  }[] = generateOfferUrl(
    apiData.urlTitle ?? '',
    apiData.id ?? '',
    apiData.seoNickname ?? '',
    apiData.numProperties ?? '',
    seoData.marsha ?? '',
    languageCodes,
    seoData.locale,
    seoData?.isPreview === 'true'
  );

  //temp mocking seo metadata
  const metaProperties = props.model?.metaProperties ?? [
    {
      name: 'og:title',
      value: '$title',
    },
    {
      name: 'og:description',
      value: '',
    },
    {
      name: 'imageURL',
      value: '',
    },
    {
      name: 'og:url',
      value: '',
    },
    {
      name: 'og:type',
      value: 'website',
    },
    {
      name: 'og:site_name',
      value: 'Marriott International',
    },
    {
      name: 'fbAppId',
      value: '311568359040551',
    },
  ];

  const metaNames = props.model?.metaNames ?? [
    {
      name: 'keyword',
      value: '',
    },
    {
      name: 'msapplication-TileColor',
      value: '',
    },
    {
      name: 'msapplication-TileImage',
      value: '',
    },
    {
      name: 'onLoadLightboxURI',
      value: '',
    },
    {
      name: 'description',
      value: '',
    },
    {
      name: 'showLightboxOnLoad',
      value: '',
    },
    {
      name: 'robots',
      value: 'index,follow',
    },
  ];

  const mappedProperties = metaProperties.map((meta: { name: string; value: string }) => {
    if (meta.name.includes('og:title')) {
      meta.value = title;
      return meta;
    }
    if (/og:description|description/.test(meta.name)) {
      meta.value = apiData?.descriptionTeaser ?? '';
      return meta;
    }
    if (/og:image|imageURL/.test(meta.name)) {
      meta.value = `${OFFER_IMAGE_CACHE_DOMAIN}${image?.url}`;
      return meta;
    }
    if (/og:url/.test(meta.name)) {
      meta.value = updatedUrl ? updatedUrl[0]['url'] : '';
      return meta;
    }
    if (meta.value.trim() === '' && apiData[`${meta.name}`]) {
      meta.value = apiData[`${meta.name}`];
    }
    return meta;
  });

  const mappedMetaNames = metaNames.map((meta: { name: string; value: string }) => {
    //AEM send "index,follow" by default
    if (meta.name === 'robots' && !apiData.searchable) {
      meta.value = NO_INDEX_NO_FOLLOW;
      return meta;
    }
    if (/og:description|description/.test(meta.name)) {
      meta.value = apiData?.descriptionTeaser ?? '';
      return meta;
    }
    if (meta.value.trim() === '' && apiData[`${meta.name}`]) {
      meta.value = apiData[`${meta.name}`];
    }
    return meta;
  });
  const hreflangUrls = hreflangList?.map(item => ({
    href: item.url,
    hreflang: item.hreflang,
  }));
  props.model['metaProperties'] = mappedProperties;
  props.model['metaNames'] = mappedMetaNames;
  props.model['title'] = title;
  props.model['canonical'] = updatedUrl ? updatedUrl[0]['url'] : '';
  props.model['hreflangList'] = hreflangUrls;
};

export const updateDataLayer = (servicedata: any) => {
  const dataLayer: any = {};
  const apiData = servicedata?.responseObject?.edges[0]?.node;
  const uri = servicedata?.uri ?? null;
  const partProperties = apiData?.participatingProperties ?? {};

  dataLayer['brndCode'] = servicedata?.brand;
  dataLayer['hotelLoc'] = Object.keys(partProperties)?.length ? processLoc(partProperties, ',') : '';
  dataLayer['mrshaCode'] = servicedata?.marsha;
  dataLayer['offer_title'] = apiData?.title;
  dataLayer['promo_single_or_multi_property'] = uri !== null ? checkOfferType(uri) : '';
  dataLayer['promo_special_offer_id'] = servicedata?.offerId;

  dataLayer['prop_address_city'] = partProperties?.city;
  dataLayer['prop_address_city_state_country'] = Object.keys(partProperties)?.length
    ? processLoc(partProperties, '|')
    : '';
  dataLayer['prop_address_country_abbr'] = partProperties?.countryCode ?? '';
  dataLayer['prop_brand_code'] = servicedata?.brand;
  dataLayer['prop_brand_name'] = Object.keys(partProperties)?.length ? partProperties.brandName : '';
  dataLayer['prop_marsha_code'] = servicedata?.marsha;
  dataLayer['prop_name'] = partProperties?.properties?.[0]?.basicInformation?.name;
  dataLayer['prop_currency_type'] = partProperties?.properties?.[0]?.basicInformation?.currency;
  dataLayer['prop_address_state_abbr'] = partProperties?.stateCode ?? '';
  dataLayer['prop_address_state_name'] = partProperties?.stateProvince ?? '';

  dataLayer['search_cluster_code'] = apiData?.clusterCode;
  return dataLayer;
};

const processLoc = (obj: any, separator: string) => {
  const locArr: string[] = [];
  ['city', 'state', 'country'].forEach(item => {
    if (obj[item] && obj[item].trim() !== '') {
      locArr.push(obj[item]);
    }
  });
  return locArr.join(separator);
};

const checkOfferType = (uri: string) => {
  if (uri.toLowerCase().indexOf('offers-spo') > -1) {
    return 'SPO_OFFER';
  }
  if (uri.toLowerCase().indexOf('offers-mpo') > -1) {
    return 'MPO_OFFER';
  }
  if (uri.toLowerCase().indexOf('offers-apo') > -1) {
    return 'ANC_OFFER';
  }
  return '';
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const processDomainUrl = (url: string) => {
  let modUrl = url;
  if (isValidUrl(url)) {
    if (url.includes('/offers/')) {
      modUrl = '/offers/' + url.split('/offers/').pop();
    }
  }
  return modUrl;
};

export const makeDomainSpecificContent = (content = '') => {
  // CNWEB-3177 To mitigate issue for .CN urls
  // needs to be revisited for a proper fix for all URLs
  if (content?.includes(MARRIOTT_DOMAIN_CN)) {
    return content.replace(MARRIOTT_DOMAIN_CN, '');
  }

  return content && content.includes('https://www.marriott.com')
    ? content.replace('https://www.marriott.com', '')
    : content;
};

let SUBDIRECTORY_PREFIX = '';

export const setOffersSubDirectoryPrefix = (prefix = '') => (SUBDIRECTORY_PREFIX = prefix);

export const addSubDirectoryPrefix = (url = '') =>
  SUBDIRECTORY_PREFIX &&
  url?.startsWith('/') &&
  url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
    ? SUBDIRECTORY_PREFIX
      ? '/' + SUBDIRECTORY_PREFIX + url
      : url
    : url;

const findNthOccurence = (str: string, nth: number, char: string) => {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};

export const setSessionKey = (key: string, value: string) => {
  if (canUseDOM) {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, value);
  }
};

export const processAcceptLang = (locale: string) => {
  return locale ? (locale?.includes(',') ? locale?.split(',')[0] : locale?.replace('_', '-')) : locale;
};

export const checkEliteUser = (datalayer: any) => {
  let rewardMemberLevel =
    datalayer?.['mr_id'] && datalayer?.['mr_prof_rewards_level'] ? datalayer?.['mr_prof_rewards_level'] : '';
  let isEliteUser = false;
  if (rewardMemberLevel) {
    rewardMemberLevel = (rewardMemberLevel as string).split(' ')?.map((item: string) => item.trim())[0];
    isEliteUser = true;
  }
  return { isEliteUser, memberLevel: rewardMemberLevel };
};

const getPosition = (string: string, subString: string, index: number) => {
  return string.split(subString, index).join(subString).length;
};
const getImagePath = (ren: any, imagePath: string) => {
  let referencePath = ren?.renditionPath.slice(0, ren?.renditionPath.indexOf(','));
  referencePath = referencePath.includes('https') ? referencePath : `${IMAGE_CACHE_DOMAIN}${referencePath}`;
  const urlParams = new URL(referencePath).searchParams;
  const width = ren?.width && ren?.width !== 0 ? ren?.width : urlParams.get('wid');
  const height = ren?.height && ren?.height !== 0 ? ren?.height : urlParams.get('hei') ?? '';
  const fit = urlParams.get('fit');
  const rendition =
    IMAGE_CACHE_DOMAIN + imagePath + '?wid=' + width + (height ? '&hei=' + height : '') + (fit ? '&fit=' + fit : '');
  const rendition2x =
    IMAGE_CACHE_DOMAIN +
    imagePath +
    '?wid=' +
    2 * width +
    (height ? '&hei=' + 2 * height : '') +
    (fit ? '&fit=' + fit : '');
  return `${rendition}, ${rendition2x} 2x`;
};

export const getMediaSource = (offersDataMedia: any, expectedSource: any, aspectRatio?: any): string => {
  //updated logic
  if (expectedSource == PRIMARY_MEDIA) {
    let primaryMatchCount = 0;
    for (let i = 0; i <= aspectRatio?.length; i++) {
      if (offersDataMedia?.primaryImage?.imageUrls?.[aspectRatio[i]]) {
        primaryMatchCount++;
      }
    }
    if (primaryMatchCount) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else {
    if (offersDataMedia?.secondaryImage?.imageSrc) {
      return SECONDARY_MEDIA;
    } else {
      return 'model';
    }
  }
};

export const getUXLMediaSource = (uxlMedia: any, expectedSource: string): string => {
  if (expectedSource == SECONDARY_MEDIA) {
    if (uxlMedia?.secondaryImage?.imageSrc) {
      return SECONDARY_MEDIA;
    } else if (uxlMedia?.primaryImage?.imageSrc) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else if (expectedSource == PRIMARY_MEDIA) {
    if (uxlMedia?.primaryImage?.imageSrc) {
      return PRIMARY_MEDIA;
    } else {
      return 'model';
    }
  } else {
    return 'model';
  }
};

export const getMediaDataSecondary = (media: any, model: any, mediaSource: string) => {
  return {
    renditions: [],
    fileReferenceBackgroundImage: mediaSource == SECONDARY_MEDIA ? media?.secondaryImage?.imageSrc : '',
    altText:
      mediaSource == PRIMARY_MEDIA
        ? media?.primaryImage?.alternateDescription
        : mediaSource == SECONDARY_MEDIA
        ? media?.secondaryImage?.alternateDescription
        : '',
    dynamic: true,
    assetPath: media?.secondaryImage?.imageSrc
      ? `${IMAGE_CACHE_DOMAIN}${media?.secondaryImage?.imageSrc}`
      : model?.assetPath,
  };
};

export const getMediaData = (
  media: any,
  model: any,
  mediaSource: string,
  expectedMedia: string,
  asptRatio?: string
) => {
  const mediaData =
    mediaSource == PRIMARY_MEDIA ? media?.primaryImage : mediaSource == SECONDARY_MEDIA ? media?.secondaryImage : null;
  const imgAspectRatio = asptRatio ?? WIDE_HOR_IMG;
  const renditionsArr = [];
  let imgSrcPath = '';
  if (expectedMedia == PRIMARY_MEDIA) {
    for (let i = 0; i < model?.renditions?.length; i++) {
      const ren = model?.renditions[i];
      const aspectRatio = ren?.renditionPath?.slice(
        getPosition(ren?.renditionPath, ':', 2) + 1,
        ren?.renditionPath.indexOf('?')
      );
      const key = aspectRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
      const imagePath = mediaData?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[key]];
      const renditionObj = {
        renditionPath: getImagePath(ren, imagePath),
        mediaValue: ren?.mediaValue,
        width: ren?.width,
        dynamic: ren?.dynamic,
        mediaQuery: ren?.mediaQuery,
        height: ren?.height,
        damPath: '',
      };
      renditionsArr.push(renditionObj);
    }
  }
  const key = imgAspectRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
  imgSrcPath =
    mediaSource === PRIMARY_MEDIA
      ? mediaData?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[key]]
      : mediaSource === SECONDARY_MEDIA
      ? media?.secondaryImage?.imageSrc
      : '';
  return {
    renditions: renditionsArr,
    fileReferenceBackgroundImage:
      mediaSource == PRIMARY_MEDIA
        ? media?.primaryImage?.imageSrc
        : mediaSource == SECONDARY_MEDIA
        ? media?.secondaryImage?.imageSrc
        : '',
    altText: PRIMARY_MEDIA
      ? media?.primaryImage?.alternateDescription
      : mediaSource == SECONDARY_MEDIA
      ? media?.secondaryImage?.alternateDescription
      : '',
    dynamic: true,
    assetPath: imgSrcPath
      ? `${IMAGE_CACHE_DOMAIN}${imgSrcPath}`
      : renditionsArr?.length
      ? renditionsArr[0]?.renditionPath?.slice(0, getPosition(renditionsArr[0]?.renditionPath, ':', 2))
      : mediaData?.imageSrc
      ? `${IMAGE_CACHE_DOMAIN}${mediaData?.imageSrc}`
      : model?.assetPath,
  };
};

export const getEdgeHost = (cookies: string) => {
  const cookieString = decodeURIComponent(cookies);
  const strValue = getCookieValue(cookieString, MBOX_COOKIE);
  const match = extractValue(strValue, 'PC#', '#') ?? '';
  let edgeValue = '';
  if (match.split('.').length > 1 && match.split('.')[1].split('_').length > 1) {
    edgeValue = match.split('.')[1].split('_')[0];

    return edgeValue;
  } else return null;
};

export const getImageWithDomain = (imgSrc: string): string => {
  if (imgSrc) {
    return `${IMAGE_CACHE_DOMAIN + imgSrc}`;
  } else {
    return '';
  }
};

export const getParamValue = (urlParams: any, paramVal: string) => {
  return urlParams?.has(paramVal) && urlParams?.get(paramVal) !== null && urlParams?.get(paramVal) !== ''
    ? urlParams?.get(paramVal)
    : '';
};

export const getDeepLinkParams = (urlParams: any, ariesSearch?: any) => {
  let searchByGeoLoc: boolean = false;
  let searchBystate: boolean = false;
  let searchBycountry: boolean = false;
  let searchByOffersIds: boolean = false;
  let offerIds: string[] | undefined = [];
  let isEmptyOfferIds: boolean = false;
  let propertyCodes: string[] | undefined = [];
  let searchByPropertyCodes: boolean = false;
  let isEmptyPropertyCodes: boolean = false;
  let searchByStayBasedOfferFlag: boolean = false;
  let isEmptyStayBasedOfferFlag: boolean = false;
  let destinationCitySearched: string | null = '';
  let hasPlaceId: string | null = '';
  let destinationSearched: string | null = null;
  let notEligible: boolean = false;
  let invalidUser: boolean = false;
  let invalidDeal: boolean = false;
  let numAdultsPerRoom: string | null = '1';
  let numChildrenPerRoom: string | null = '0';
  let numGuestsPerRoom: string | null = '1';
  let numRooms: string | null = '1';
  notEligible = urlParams?.has('notEligible') ? true : false;
  invalidUser = urlParams?.has('invalidUserMsg') ? true : false;
  invalidDeal = urlParams?.has('invalidDealMsg') ? true : false;
  hasPlaceId = getParamValue(urlParams, 'destinationAddress.placeId');
  destinationSearched = getParamValue(urlParams, 'destinationAddress.destination');
  destinationCitySearched = getParamValue(urlParams, 'destinationAddress.city');
  searchByGeoLoc =
    (ariesSearch?.latitude && ariesSearch?.longitude) ||
    (getParamValue(urlParams, 'destinationAddress.latitude') &&
      getParamValue(urlParams, 'destinationAddress.longitude'));
  searchBystate = ariesSearch?.stateProvince || getParamValue(urlParams, 'destinationAddress.stateProvince');
  searchBycountry = ariesSearch?.country || getParamValue(urlParams, 'destinationAddress.country');
  offerIds = urlParams?.has('offers') && urlParams?.get('offers') !== '' ? urlParams?.get('offers')?.split(',') : [];
  searchByOffersIds =
    urlParams?.has('offers') && urlParams?.get('offers') !== '' && urlParams?.get('offers')?.split(',')?.length
      ? true
      : false;
  isEmptyOfferIds = urlParams?.has('offers') && urlParams?.get('offers') === '';
  propertyCodes =
    urlParams?.has('propertycodes') && urlParams?.get('propertycodes') !== ''
      ? urlParams?.get('propertycodes')?.split(',')
      : urlParams?.has('propertyCode') && urlParams?.get('propertyCode') !== ''
      ? urlParams?.get('propertyCode')?.split(',')
      : [];

  searchByPropertyCodes =
    (urlParams?.has('propertycodes') &&
      urlParams?.get('propertycodes') !== '' &&
      urlParams?.get('propertycodes')?.split(',').length) ||
    (urlParams?.has('propertyCode') &&
      urlParams?.get('propertyCode') !== '' &&
      urlParams?.get('propertyCode')?.split(',').length)
      ? true
      : false;
  isEmptyPropertyCodes =
    (urlParams?.has('propertycodes') && urlParams?.get('propertycodes') === '') ||
    (urlParams?.has('propertyCode') && urlParams?.get('propertyCode') === '');
  searchByStayBasedOfferFlag = urlParams?.has('stayBasedOffer') && urlParams?.get('stayBasedOffer') == 'false';
  isEmptyStayBasedOfferFlag = urlParams?.has('stayBasedOffer') && urlParams?.get('stayBasedOffer') === '';
  numAdultsPerRoom = urlParams?.has('numAdultsPerRoom') ? urlParams?.get('numAdultsPerRoom') : '1';
  numChildrenPerRoom = urlParams?.has('childrenCount') ? urlParams?.get('childrenCount') : '0';
  numGuestsPerRoom = urlParams?.has('guestCountBox') ? urlParams?.get('guestCountBox') : '1';
  numRooms = urlParams?.has('roomCount') ? urlParams?.get('roomCount') : '1';
  const deepLinkParamObj = {
    isEmptyStayBasedOfferFlag: isEmptyStayBasedOfferFlag,
    searchByStayBasedOfferFlag: searchByStayBasedOfferFlag,
    isEmptyPropertyCodes: isEmptyPropertyCodes,
    searchByPropertyCodes: searchByPropertyCodes,
    propertyCodes: propertyCodes,
    isEmptyOfferIds: isEmptyOfferIds,
    searchByOffersIds: searchByOffersIds,
    offerIds: offerIds,
    searchBycountry: searchBycountry,
    searchBystate: searchBystate,
    searchByGeoLoc: searchByGeoLoc,
    destinationCitySearched: destinationCitySearched,
    destinationSearched: destinationSearched,
    hasPlaceId: hasPlaceId,
    notEligible: notEligible,
    invalidUser: invalidUser,
    invalidDeal: invalidDeal,
    numAdultsPerRoom: numAdultsPerRoom,
    numChildrenPerRoom: numChildrenPerRoom,
    numGuestsPerRoom: numGuestsPerRoom,
    numRooms: numRooms,
  };
  return deepLinkParamObj;
};

export const generateUXLVariablesForDestination = (
  latitude: string,
  longitude: string,
  stateCode: any,
  countryCode: any,
  isStateEnabled: any,
  isCountryEnabled: any,
  isPOI: any
) => {
  const obj: any = {
    limit: 15,
    offset: 0,
    participatingPropertiesLimit2: 1,
  };
  if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
    return {
      ...obj,
      input: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      },
      sort: {
        field: 'OFFER_DISTANCE',
      },
    };
  } else if (isStateEnabled && !isCountryEnabled) {
    return {
      ...obj,
      input: {
        state: stateCode,
        country: countryCode,
      },
      sort: {
        field: 'BOOKING_END_DATE',
      },
    };
  } else if (!isStateEnabled && isCountryEnabled) {
    return {
      ...obj,
      input: {
        country: countryCode,
      },
      sort: {
        field: 'BOOKING_END_DATE',
      },
    };
  }
};
const brandLabelMappings: Record<string, string> = {
  luxuryBrands: 'offersSelectAllLuxuryBrands',
  premiumBrands: 'offersSelectAllPremiumBrands',
  selectBrands: 'offersSelectAllSelectBrands',
  longerStayBrands: 'offersSelectAllLongerStaysBrands',
};
export const getBrandCategorySelectAllLabel = (category: string): string => {
  return brandLabelMappings[category] || '';
};

export const getControlsData = (currentFilterData: any) => {
  const desiredKeys = ['code', 'count', 'label', 'description'];
  const updatedFilterOptions = currentFilterData?.map((obj: any) => {
    const newFilterOptionsObj: any = {};
    desiredKeys?.forEach(key => {
      newFilterOptionsObj[key] = obj[key];
    });
    const modifiedLabel = obj?.description;
    return { ...newFilterOptionsObj, label: modifiedLabel };
  });
  return updatedFilterOptions;
};
export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0'); // Ensure two digits

  return `${year}-${month}-${day}`;
};
export const getTomorrowDate = (fromOSERP: boolean = false) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // Add one day

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(tomorrow.getDate()).padStart(2, '0'); // Ensure two digits

  return fromOSERP ? `${year}/${month}/${day}` : `${year}-${month}-${day}`;
};

export const getOffersDateFormatted = (date: any): string => {
  return date?.format('MM/DD/YYYY') ?? '';
};

export const calculateTimeLeft = (startDate: string, isAuthorMode: boolean): TimeLeft => {
  if (isAuthorMode) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  const start = new Date(startDate);
  const now = new Date();
  const difference = start.getTime() - now.getTime();

  if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
};

export const checkCampaignStatus = (
  localTimeEST: Date | null,
  campaignStartDate: string,
  campaignEndDate: string
): string => {
  if (!localTimeEST) {
    return constants.CAMPAIGN_INACTIVE;
  }

  const startTime = new Date(campaignStartDate).getTime();
  const endTime = new Date(campaignEndDate).getTime();
  const currentTime = localTimeEST.getTime();

  // First, check if it's within the campaign period (active)
  const state =
    currentTime >= startTime && currentTime < endTime ? constants.CAMPAIGN_ACTIVE : constants.CAMPAIGN_INACTIVE;

  return state;
};

export const getLocalTimeEST = () => {
  const localDate = new Date();
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
  const estOffset = -5; // EST is UTC-5
  const estDate = new Date(utcDate.getTime() + estOffset * 60 * 60000);
  return estDate;
};

export const isUserAuthenticated = (data: any) => {
  // Check if data exists and has elements
  if (data?.data?.length) {
    const { memState, mr_id } = data.data[0];
    const isMrIdValid = mr_id != null;
    return isMrIdValid && memState === 'authenticated';
  }
  return false;
}; // Default to unauthenticated

// common functions for offercard and offercardCarousel
export const trackingPropertiesObj = (modelProp: any) => {
  const {
    trackingContentPosition,
    trackingOfferType,
    atCCeVar48,
    trackingDescription,
    trackingTag,
    clickTrack,
    impressionEventType,
    merchandisingCategory,
    additionalTrackingVariables,
    location,
    payloadType,
    compName,
    enableScrollingBehavior,
    cardLocation,
    leftArrowDesc,
    rightArrowDesc,
    description,
  } = modelProp?.trackingProperties || {};

  return {
    trackingContentPosition,
    trackingOfferType,
    atCCeVar48,
    trackingDescription,
    trackingTag,
    isCoBrand: false,
    clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType,
    merchandisingCategory,
    additionalTrackingVariables,
    location,
    payloadType,
    compName,
    enableScrollingBehavior,
    cardLocation,
    leftArrowDesc,
    rightArrowDesc,
    description,
  };
};
export const isMemberExclusive = (memberLevel: Array<string>): boolean => {
  if (memberLevel?.find(ele => MEMBER_EXCLUSIVE.includes(ele))) {
    return true;
  }
  return false;
};
export const processOfferCarousel = (
  data: any,
  model: any,
  isRecipeEliteExclusive: any,
  envVarsObject: any,
  cardType: string,
  openInaNewTab: any,
  isPreview?: boolean
) => {
  const count = data?.offersData?.length;
  const cardKeys = Object?.keys(data?.offersData);

  const offerMap = data?.offersData?.map((card: any, index: number) => {
    const imageDomain = envVarsObject?.['UXL_IMAGE_CACHE_DOMAIN'] ?? config.IMAGE_CACHE_DOMAIN;

    let imageUri = null;
    let imageUriLg = null;
    if (card.image.includes('/is/image')) {
      imageUri = isPreview
        ? `${imageDomain}${card.image}?wid=365&fit=constrain 2x`
        : `${imageDomain}${card.image}?wid=365&fit=constrain`;
      imageUriLg = isPreview
        ? `${imageDomain}${card.image}?wid=800&fit=constrain 2x`
        : `${imageDomain}${card.image}?wid=800&fit=constrain`;
    } else {
      imageUri = isPreview
        ? `${imageDomain}${card.image}?downsize=365px:* 2x`
        : `${imageDomain}${card.image}?downsize=365px:*`;
      imageUriLg = isPreview
        ? `${imageDomain}${card.image}?downsize=800px:* 2x`
        : `${imageDomain}${card.image}?downsize=800px:*`;
    }
    const isMerchandisingOffer = card.parentOfferType === MERCHANDISING_OFFER;
    const eyeBrowText = isMerchandisingOffer
      ? card?.descriptionTeaser
      : card.propertiesCount === 1
      ? card.propertyName
      : model?.eyebrow;

    const MemberExclusive = isMemberExclusive(card.memberLevel) ?? false;
    const badgeText = isMerchandisingOffer ? card?.offerCategory : MemberExclusive ? model?.memberExclusive : '';
    const cardLocDetails = model?.trackingProperties?.location
      ? `${model.trackingProperties.location}-card-${index}`
      : `card-${index}`;
    const cardTrackingTag = model?.trackingProperties?.trackingTag
      ? `${model?.trackingProperties?.trackingTag}-${card.catalogId}`
      : card.catalogId || null;
    const cardTrackingOfferType = model?.trackingProperties?.trackingOfferType
      ? `${model?.trackingProperties?.trackingOfferType}-${card.parentOfferType}`
      : card.parentOfferType || null;
    const isCardVertical = cardType === CARDSTYPE_VERTICAL;
    const cardObj = {
      ...(isCardVertical && {
        cardVerticalVariations: CardVerticalVariations.Tall1x1,
        ':type': 'mi-aem-common-spa/components/content/cardvertical',
        description: card.description,
        ctaLinkText: 'Read More',
      }),
      ...(!isCardVertical && {
        cardVerticalVariations: CardVerticalVariations.Standard,
        cardLayeredVariations: cardType === LAYERED_WIDE ? CARD_LAYERED_WIDE : CARD_LAYERED_VARIATION,
        ':type': 'mi-aem-common-spa/components/content/cardlayered',
        description: card?.header,
        eyebrow: eyeBrowText,
      }),
    };

    return {
      ':items': {
        cardlayered_copy: {
          trackingProperties: {
            atCCeVar48: model?.trackingProperties?.atCCeVar48 || null,
            additionalTrackingVariables: model?.trackingProperties?.additionalTrackingVariables || null,
            trackingDescription: card?.header,
            description: card?.header,
            clickTrack: model?.trackingProperties?.clickTrack ? true : false,
            impressionTrack: false,
            location: cardLocDetails,
            trackingContentPosition: cardLocDetails,
            merchandisingCategory: model?.trackingProperties?.merchandisingCategory
              ? model.trackingProperties.merchandisingCategory
              : 'nonCobrand',
            impressionCount: false,
            trackingTag: cardTrackingTag,
            trackingOfferType: cardTrackingOfferType,
          },
          styleclass: model?.cardTheme,
          headerTag: 'h3',
          icon: '',
          impressionTrack: IMPRESSION_TRACK,
          badgeIcon: !isRecipeEliteExclusive ? 'icon-lock' : '',
          imgAltText: card?.header ? card.header : IMAGE_ALT_TEXT,
          clickTrack: model?.trackingProperties?.clickTrack ? true : false,
          fileReferenceImage: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
          header: card.header,
          fontSize: 't-subtitle-l',
          [cardType === LAYERED_WIDE ? 'dynamicMediaWide' : 'dynamicMedia']: {
            altText: card?.header ? card.header : IMAGE_ALT_TEXT,
            assetPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
            dynamic: true,
            renditions: [
              {
                renditionPath: imageUriLg,
                mediaValue: '992px',
                width: 0,
                dynamic: true,
                damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                mediaQuery: 'min-width',
                height: 0,
              },
              {
                renditionPath: imageUri,
                mediaValue: '576px',
                width: 0,
                dynamic: true,
                damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                mediaQuery: 'min-width',
                height: 0,
              },
              {
                renditionPath: imageUri,
                mediaValue: '576px',
                width: 0,
                dynamic: true,
                damPath: `${config.IMAGE_CACHE_DOMAIN}${card.image}`,
                mediaQuery: 'max-width',
                height: 0,
              },
            ],
            damPath: card.image,
          },
          merchandisingCategory: MERCHANDISING_CATEGORY,
          impressionCount: IMPRESSION_TRACK,
          ctaLink: addSubDirectoryPrefix(makeDomainSpecificContent(card.ctaUrl)),
          badgeText: badgeText,
          logoIcon: '',
          openInNewTab: openInaNewTab,
          ...cardObj,
        },
      },
    };
  });
  return { count, cardKeys, offerMap };
};

export const processUxlData = (
  uxlData: any,
  isFallback?: boolean,
  cardType?: string,
  model?: { maxOffersCount?: number },
  isPOI = false,
  isStateEnabled = false,
  isCountryEnabled = false,
  destinationName = ''
): { data: any; concatenatedOfferIds?: string } => {
  let response;
  let experienceId;
  let concatenatedOfferIds = '';
  if (isFallback) {
    response = uxlData?.fallbackOffers?.offerCollection || [];
  } else if (destinationName) {
    if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
      response = uxlData?.offersSearchByGeolocation?.edges || [];
    } else {
      response = uxlData?.offersSearchByArea?.edges || [];
    }
  } else {
    response = (uxlData?.offersCarousel?.length && uxlData?.offersCarousel[0]?.offerCollection) || [];
    experienceId = uxlData?.offersCarousel?.length ? uxlData?.offersCarousel[0]?.experienceId : '';
  }

  const maxOffersCount = model?.maxOffersCount ?? response?.length;
  const ASPECT_RATIO_UXL_FIELD_MAPPING = {
    Wide: 'wideHorizontal',
    Square: 'square',
  };
  const imageRatio = cardType === LAYERED_WIDE ? ASPECT_RATIO_WIDE : ASPECT_RATIO_SQUARE;

  const defaultOffers = response
    ?.filter((offer: any) => offer?.offer?.title || offer?.node?.title)
    .slice(0, maxOffersCount)
    .map((item: any, index: number) => {
      const objKey = imageRatio as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
      const DACImagePath =
        item?.offer?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]] ||
        item?.node?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];
      let imageUrl = '';

      if (DACImagePath) {
        imageUrl = DACImagePath;
      } else {
        const keyData = item?.offer?.photos?.images ?? item?.node?.photos?.images;
        keyData?.map((key: any) => {
          if (key.aspectRatio === imageRatio) {
            imageUrl = key.url;
          }
        });
      }

      if (index !== 0) {
        concatenatedOfferIds += ',';
      }
      concatenatedOfferIds += item?.offer?.id ?? item?.node?.id;
      return {
        image: imageUrl,
        header: item?.offer?.title || item?.node?.title,
        propertyName:
          item?.offer?.participatingProperties?.properties?.[0]?.basicInformation?.name ||
          item?.node?.participatingProperties?.properties?.[0]?.basicInformation?.name,
        ctaUrl: item?.offer?.url || item?.node?.url,
        propertiesCount: item?.offer?.numProperties || item?.node?.numProperties,
        memberLevel: item?.offer?.memberLevel || item?.node.memberLevel,
        description: item?.offer?.description || item?.node.description,
        catalogId: item?.catalogId,
        parentOfferType: item?.offer?.parentOfferType || item?.node?.parentOfferType,
        descriptionTeaser: item?.offer?.descriptionTeaser || item?.node?.descriptionTeaser,
        offerCategory: item?.offer?.offerCategory || item?.node?.offerCategory,
      };
    });

  return {
    data: { offersData: defaultOffers, isFallback, experienceId },
    concatenatedOfferIds: !destinationName ? concatenatedOfferIds : '',
  };
};
export const checkNoDataAvailable = (
  queryData: any,
  isPreview?: boolean,
  defaultOffersReceipe?: any,
  noOfCards?: any,
  isPOI = false,
  isStateEnabled = false,
  isCountryEnabled = false
) => {
  if (isPreview) {
    if (!queryData) return true;
    else return false;
  } else {
    //fallback query called when main recipe returns cards less than authored packs instead of hard coded 3 value
    if (defaultOffersReceipe !== DESTINATION_OFFERS_RECIPE) {
      if (!queryData?.offersCarousel?.length || queryData?.offersCarousel?.[0]?.offerCollection.length < noOfCards) {
        return true;
      } else return false;
    } else if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
      if (queryData?.offersSearchByGeolocation?.edges.length < noOfCards) {
        return true;
      } else return false;
    } else if ((!isStateEnabled && isCountryEnabled) || (isStateEnabled && !isCountryEnabled)) {
      if (queryData?.offersSearchByArea?.edges.length < noOfCards) {
        return true;
      } else return false;
    } else {
      return false;
    }
  }
};

export const removeAfterParagraph = (htmlString: string): string => {
  const parts = htmlString.split('</p>');
  return parts[0] + '</p>';
};

export const objectToArray = (data: any) => {
  return Object.keys(data).map(key => ({
    name: key,
    value: String(data[key]), // Convert each value to a string
  }));
};

// Utility function to update Robot metaName
export const updateRobotMetaNames = (metaNames: { name: string; value: string }[]) => {
  return metaNames.map(meta => {
    if (meta.name === 'robots') {
      return { ...meta, value: NO_INDEX_FOLLOW };
    }
    return meta;
  });
};

// Utility function for targetting criteria
export const getTargettingCriteria = (
  workspaceId: string | number,
  isRecipePropNonStayOffers: boolean,
  propsMarshCode: any,
  resortRequestVariables: ResortRequestVariables,
  isRecipeEliteExclusive: boolean,
  isEliteUser: boolean,
  memberLevel: string,
  edgeHost: any,
  modifiedDataLayerPayload: any
) => {
  return {
    targetingCriteria: {
      workspaceId: workspaceId, // Adobe Target Locale specific workspace Id
      ...(isRecipePropNonStayOffers && { propertyIds: propsMarshCode }),
      ...resortRequestVariables,
      ...(isRecipeEliteExclusive && isEliteUser && { memberLevel }),
      ...(edgeHost && { edgeHost }),
      ...(modifiedDataLayerPayload && { parameters: modifiedDataLayerPayload }),
    },
  };
};

// Utility function for generating request Variables
export const generateRequestVariables = (
  defaultOffersReceipe: string,
  OFFER_CAROUSEL_CHANNEL: string,
  sourceUrl: any,
  OFFER_CAROUSEL_URL_REFERRER: string,
  searchCriteria: string,
  isEliteUser: boolean,
  isRecipeEliteExclusive: boolean,
  targetingCriteria: any,
  offersSearchInputVar: OffersSearchInputVar
) => {
  return {
    optimizedOffersInput: {
      action: defaultOffersReceipe,
      source: {
        channel: OFFER_CAROUSEL_CHANNEL,
        url: sourceUrl,
        urlReferrer: OFFER_CAROUSEL_URL_REFERRER,
      },
      searchCriteria: {
        id: searchCriteria,
        ...(isEliteUser && isRecipeEliteExclusive && { isIncludeGatedOffer: true }),
      },
      ...targetingCriteria,
    },
    ...offersSearchInputVar,
  };
};
