import { baseVariables, Button } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  align-items: center;

  .swiper-horizontal {
    width: 100%;
    height: auto;
  }
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  display: flex !important; // override out-of-the-box swiper styles
  flex-direction: column;

  .swiper-slide-active {
    position: relative;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: fit-content;
    align-self: center;
    position: relative;
    bottom: 0;

    > span {
      vertical-align: middle;
    }
  }

  .carousel-arrow-container {
    @media ${baseVariables.mediaQuery.md} {
      display: none !important;
    }

    justify-content: space-evenly;

    button {
      background-color: transparent;
      position: relative;
    }

    .left-arrow {
      left: 20px;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    transition: transform 300ms;
  }

  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.95);
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;

    @media ${baseVariables.mediaQuery.md} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 50px;

      :after {
        font-size: 12px;
        font-weight: bold;
        color: ${baseVariables.color.base10};
      }
    }
  }

  .swiper-button-next {
    right: 0;
    ::after {
      color: unset;
    }
  }

  .swiper-button-prev {
    left: 0;
    ::after {
      color: unset;
    }
  }

  .swiper-pagination-bullet {
    background-color: ${baseVariables.color.base10};
  }
`;

export const StyledPlayPauseButton = styled(Button)`
  background: transparent;
  margin-right: 8px;
  padding: 0;
  width: fit-content;

  > div {
    align-content: flex-end;
    border: thin black solid;
    border-radius: 32px;
    height: 32px;
    width: 32px;
  }
`;
